$DEFAULT_NAVBAR_HEIGHT: 64px;
$MOBILE_NAVBAR_HEIGHT: 56px;

.header-wrapper {
  // Material UI adds inline styles for absolute positioned offsets
  top: $DEFAULT_NAVBAR_HEIGHT !important;
  
  .MuiBackdrop-root {
    top: $DEFAULT_NAVBAR_HEIGHT;
  }

  .MuiDrawer-modal {
    top: $DEFAULT_NAVBAR_HEIGHT !important;
  }

  .MuiDrawer-paperAnchorTop {
    top: $DEFAULT_NAVBAR_HEIGHT;
  }
}

@media(max-width: 600px) {
  .header-wrapper {
    top: $MOBILE_NAVBAR_HEIGHT !important;
    
    .MuiBackdrop-root {
      top: $MOBILE_NAVBAR_HEIGHT;
    }
  
    .MuiDrawer-modal {
      top: $MOBILE_NAVBAR_HEIGHT !important;
    }
  
    .MuiDrawer-paperAnchorTop {
      top: $MOBILE_NAVBAR_HEIGHT;
      height: 75vh;
      overflow: scroll;
    }
  }
}
