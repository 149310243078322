.details-wrapper {
  min-height: inherit;
  background-color: white;
}

.header--image {
  width: 100%;
  height: 30vh;
  background-image: url('../../images/our_groups_navcard.jpg');
  background-position: 50% 60%;
  background-repeat: no-repeat;
}

.details-title {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.logo-title__align {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 1rem;
  }
}