.group-wrapper {
  min-height: inherit;
  background-color: white;
}

.header--image {
  width: 100%;
  height: 30vh;
  background-image: url('../../images/our_groups_navcard.jpg');
  background-position: 50% 60%;
  background-repeat: no-repeat;
}

.group-title {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.logo-title__align {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 1rem;
  }
}

.sipoc-card-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sipoc-card {
  height: 5rem;
  padding: 1rem;
  background-color: lightgrey;
}

.raci-row {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 2rem;
}

.raci-category__center {
  text-align: center;
}

.members-wrapper__padding {
  padding-top: 1rem;
}
