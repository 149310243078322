#root {
  min-height: 100vh;
}

body {
  font-family: Karbon, sans-serif;
  margin: 0px;
  background: rgb(0,65,123);
  background: linear-gradient(60deg, rgba(0,120,180,1) 0%, rgba(1,163,226,1) 46%, rgba(240,240,255,1) 100%);
}

@font-face {
  font-family: 'Karbon-Regular';
  src: "../../fonts/Karbon-Regular.otf" format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

