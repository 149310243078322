.quality-investigations-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  .header-wrapper{
    display: flex;
    flex-direction: row;
    padding-bottom: 2rem;
    .content-title{
      margin-left: auto;
      margin-right: auto;
    }
  }
  .filter-wrapper{
  }

  .table-wrapper{
    display: flex;
    width: 55%;
    flex-direction: column;
  }

  .sidebar-wrapper{
  }
}

.result-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.search-bar-wrapper{
  display:flex;
  justify-content: center;
  margin-bottom: 1rem;
}
@media(max-width: 600px) {
  .result-wrapper {
    flex-direction: column;
  }
}