.toolBarContainer{
  display: flex;
  .searchContainer{
    margin-right: 1em;
  }
  .iconContainer{
    .icon{
      color: rgb(0,65,123);
      flex-grow: 1;
    }
  }
}