div.cart-background{
    width: 80%;
    margin: auto;
    margin-top: 16px;
    padding-bottom: 16px;
    min-height: 600px;
}
div.floating-btn-group{
   text-align: center;
   min-width: 350px;
   position: fixed;
   bottom: 0px;
   right: 0px;
}
div.section-header{
    text-align: center;
    margin: auto;
}
div.no-content{
    text-align: center;
    margin: auto;
    margin-top: 50px;
    padding: 1em;
}
h4.cart-title{
    padding: 24px;
}

@media only screen and (max-width: 600px) {
    div.cart-background{
        width: 90%;
    }
    div.floating-btn-group{
        width: 100%;
        max-height: 50px;
    }
  }