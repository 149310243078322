.content-home{
  display: flex;
  flex-direction: column;
  background: white;
  .header--image {
    width: 100%;
    height: 30vh;
    background-image: url('../../../images/our_groups_navcard.jpg');
    background-position: 50% 60%;
    background-repeat: no-repeat;
  }
  .content-company-name{
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  .content-home-wrapper {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
  }
}


.content-mailto-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mail-icon__shift-left {
  margin-left: 1rem;
}

.content-home-info__space-apart {
  & + .content-home-info__space-apart {
    margin-top: 1rem;
  }

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
}

.alternative-names__remove-style-type {
  list-style-type: none;
}