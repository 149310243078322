.result-item-wrapper {
  display: flex;
  flex-direction: row;
  
  & + .result-item-wrapper {
    margin-top: 1rem;
  }

  .button-wrapper {
    width: 25%;
    margin-right: 1em;
  }

  .result-button {
    width: 100%;
    height: 100%;
  }

  .result-image {
    width: 100%;
    height: 100%;
  }

  .field-wrapper {
    width: 70%;
  }

  .title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.details-row {
  display: table;

  h6 {
    display: table-cell;
    padding: 0 1rem;
  }
}
