.search-wrapper {
  padding: 2rem;
  background: white;

  .search-asides__flex-row {
    display: flex;
    flex-direction: row;
    padding-top: 1.5rem;
  }
}

.search-filters {
  width: 25%;
}

.search-results {
  width: 100%;
}

.aside-content__padding-top {
  padding-top: 1rem;
}

div.search-filter {
  display:flex;
  flex-direction: column;
  width: 70%;
}