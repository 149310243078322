.group-supplier-container {
  display: flex;
  flex-direction: row;

  .group-supplier--col + .group-supplier--col {
    margin-left: 1rem;
  }
}

.group-supplier--row {
  display: flex;
  flex-direction: row;

  &-details {
    margin-left: 1rem;
  }
}

.supplier-profile-image__resize {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  object-position: center;
}
