.sipoc-modal-container__center-contents {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.sipoc-modal-contents {
  background-color: white;
  height: 75vh;
  width: 85vh;
  position: relative;
}

.sipoc-modal-header {
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.sipoc-modal--main-content {
  height: 80%;
  overflow-y: scroll;
}

svg.MuiSvgIcon-root.sipoc-navigation-arrow {
  //requires min weight 0021 to override
  color: aliceblue;
  font-size: 5rem;
  cursor: pointer;
}

