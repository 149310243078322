.menu-dropdown {
  display: flex;
  flex-direction: row;
}

.menu-content__hide {
  display: none;
}

.menuNav {
  width: 20%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menuNavPanel {
  background-color:#4B82BE;
  color: white;
  text-align: center;
  font-size: 1.4rem;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;

  &+.menuNavPanel {
    border-top: 1px solid white;
  }

  &:hover {
    filter: brightness(125%);
  }
}

.menuNavPanelActive {
  background-color: white;
  color: #4B82BE;
}

.menuContentArea {
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: white;  
}

@media only screen and (max-width: 600px) {
  .menu-dropdown {
    flex-direction: column;
  }
  
  .menuNav {
    width: 100%;
    height: auto;
  }

  .menuNavPanel {
    line-height: 50px;
  }
}
