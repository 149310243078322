.quality-investigations-wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2em;
    padding-right: 2em;
    .search-bar-wrapper{
      display:flex;
      justify-content: center;
    }
  
    .result-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .filter-wrapper{
      }
  
      .table-wrapper{
        display: flex;
        width: 55%;
        flex-direction: column;
      }
  
      .sidebar-wrapper{
      }
    }
  }